import { KpiAPI, getSelectedCampaignRegex } from "../apiKpi";
import moment from "moment";
import { store } from "../../_redux";
import { getSelectedWorkspaceId } from "lib/workspaces";

function getAccounts({ userId, connectionId }) {
    const workspaceId = getSelectedWorkspaceId();

    return KpiAPI({
        method: "GET",
        url: `/adwords/accounts`,
        query: {
            userId: workspaceId || userId,
            connectionId,
        },
    })
        .then(({ data }) => {
            return {
                connectionId,
                platform: "adwords",
                integrations: {
                    adwords: data.reduce(
                        (obj, next) => ({
                            ...obj,
                            [next.id]: next,
                        }),
                        {},
                    ),
                },
            };
        })
        .catch((error) => ({ data: { error: true } }));
}

function getCampaigns({ userId, connectionId, adwordsId }) {
    const workspaceId = getSelectedWorkspaceId();

    return KpiAPI({
        method: "GET",
        url: `/adwords/campaigns`,
        query: {
            userId: workspaceId || userId,
            connectionId,
            adwordsId,
        },
    })
        .then(({ data }) => {
            return {
                connectionId,
                adwordsId,
                campaigns: {
                    adwords: data,
                },
            };
        })
        .catch((error) => ({ data: { error: true } }));
}

export function getAdwordsAdGroupData({ dateRange, accountId }) {
    if (dateRange) {
        const validatedDateRange = dateRange
            .filter((obj) => {
                return obj.hasOwnProperty("start") && obj.hasOwnProperty("end");
            })
            .map(({ start, end }) => {
                return {
                    start: moment(start).format("YYYY-MM-DD"),
                    end: moment(end).format("YYYY-MM-DD"),
                };
            });

        dateRange = JSON.stringify(validatedDateRange);
    }

    return KpiAPI({
        method: "GET",
        url: "/channels/campaign",
        query: {
            accountId,
            dateRange,
            aggregation: "day",
            scope: "adwords",
            startDate: moment(dateRange.start).format("YYYY-MM-DD"),
            endDate: moment(dateRange.end).format("YYYY-MM-DD"),
            metrics: [
                "campaign",
                "campaignId",
                "cost",
                "conversions",
                "clicks",
                "impressions",
                "date",
                "adGroup",
                "adGroupID",
                "network",
                "phoneCalls",
                "phoneImpressions",
                "phoneThroughRate",
            ].join(","),
        },
    }).then(({ data: { adwords } = {} } = {}) => {
        return adwords;
    });
}

export function getAds({ dateRange, accountId }) {
    const campaignRegex = getSelectedCampaignRegex(store.getState());
    return Promise.all(
        dateRange.map(({ start, end }) =>
            KpiAPI({
                method: "GET",
                url: `/ads`,
                query: {
                    accountId,
                    aggregation: "day",
                    scope: "adwords",
                    startDate: moment(start).format("YYYY-MM-DD"),
                    endDate: moment(end).format("YYYY-MM-DD"),
                },
            }),
        ),
    )
        .then(([result, prevResult]) => {
            return {
                current: result.data.adwords.filter(
                    (item) => !campaignRegex || item.campaign.match(new RegExp(campaignRegex)),
                ),
                previous: !prevResult
                    ? []
                    : prevResult.data.adwords.filter(
                          (item) => !campaignRegex || item.campaign.match(new RegExp(campaignRegex)),
                      ),
            };
        })
        .catch((err) => {
            return err;
        });
}

export function getSearchTermsReport({ dateRange, accountId }) {
    if (dateRange) {
        const validatedDateRange = dateRange
            .filter((obj) => {
                return obj.hasOwnProperty("start") && obj.hasOwnProperty("end");
            })
            .map(({ start, end }) => {
                return {
                    start: moment(start).format("YYYY-MM-DD"),
                    end: moment(end).format("YYYY-MM-DD"),
                };
            });

        dateRange = JSON.stringify(validatedDateRange);
    }

    return KpiAPI({
        method: "GET",
        url: "/adwords/searchTerms",
        query: {
            accountId,
            dateRange,
            startDate: moment(dateRange.start).format("YYYY-MM-DD"),
            endDate: moment(dateRange.end).format("YYYY-MM-DD"),
        },
    }).then(({ data } = {}) => {
        return data;
    });
}

export function getCampaignAssetReport({ dateRange, accountId, comparison, abortController }) {
    const { start, end, compareToStart, compareToEnd } = dateRange || {};

    return KpiAPI({
        method: "GET",
        url: "/adwords/campaignAssets",
        query: {
            accountId,
            date: end,
            startDate: start,
            endDate: end,
            compareToStartDate: compareToStart,
            compareToEndDate: compareToEnd,
            compareTo: comparison,
        },
        abortController,
    }).then(({ data } = {}) => {
        return data;
    });
}

export function getAssetGroupReport({ dateRange, accountId, comparison, abortController }) {
    const { start, end, compareToStart, compareToEnd } = dateRange || {};

    return KpiAPI({
        method: "GET",
        url: "/adwords/assetGroups",
        query: {
            accountId,
            date: end,
            startDate: start,
            endDate: end,
            compareToStartDate: compareToStart,
            compareToEndDate: compareToEnd,
            compareTo: comparison,
        },
        abortController,
    }).then(({ data } = {}) => {
        return data;
    });
}

export function getAssetGroupAssets({ accountId, abortController }) {
    return KpiAPI({
        method: "GET",
        url: "/adwords/assetGroupAssets",
        query: {
            accountId,
        },
        abortController,
    }).then(({ data } = {}) => {
        return data;
    });
}

export default {
    getAccounts,
    getCampaigns,
    getAdwordsAdGroupData,
    getAds,
    getSearchTermsReport,
    getCampaignAssetReport,
    getAssetGroupReport,
    getAssetGroupAssets,
};
