import { KpiAPI } from "lib/apiKpi";
import { getSelectedWorkspaceId } from "lib/workspaces";

function getAccounts({ userId, connectionId }) {
    const workspaceId = getSelectedWorkspaceId();

    return KpiAPI({
        method: "GET",
        url: `/tiktok/accounts`,
        query: {
            userId: workspaceId || userId,
            connectionId,
        },
    })
        .then(({ data }) => {
            return {
                connectionId,
                platform: "tiktok",
                integrations: {
                    tiktok: data.reduce(
                        (obj, next) => ({
                            ...obj,
                            [next.advertiserId]: next,
                        }),
                        {},
                    ),
                },
            };
        })
        .catch((error) => ({ data: { error: true } }));
}

function getCampaigns({ userId, connectionId, advertiserId }) {
    const workspaceId = getSelectedWorkspaceId();

    return KpiAPI({
        method: "GET",
        url: `/tiktok/campaigns`,
        query: {
            userId: workspaceId || userId,
            connectionId,
            advertiserId,
        },
    })
        .then(({ data }) => {
            return {
                connectionId,
                advertiserId,
                campaigns: {
                    tiktok: data,
                },
            };
        })
        .catch((error) => ({ data: { error: true } }));
}

export default {
    getAccounts,
    getCampaigns,
};
