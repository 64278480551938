import React, { useState, useCallback, useEffect, useMemo } from "react";
import style from "./style.module.scss";
import { useDispatch, useSelector } from "react-redux";
import {
    selectShowExportManager,
    makeSelectAccountsPerformance,
    makeSelectAccounts,
    makeSelectAccountsMetrics,
} from "features/ControlPanel/ducks/selectors";
import { makeSelectCustomMetrics, selectSavedMetrics } from "_redux/users/selectors";
import { actions } from "features/ControlPanel/ducks";
import { CSVLink } from "react-csv";

import {
    metricFriendlyName,
    capitalize,
    channelFriendlyName,
    getPlatformMainKey,
    PlatformAndChannelLabel,
    platformGoalsSort,
} from "lib/utility";
import { metricInfo } from "lib/utility";
import { defaultGoals } from "features/Dashboard/_ducks/reducers";

import { DialogVFour, CloseDialog } from "components/Dialog";
import Button from "components/Button";
import SmallTabs from "components/Tabs/SmallTabs";
import CheckboxSearch from "components/SearchBox/CheckboxSearchWithComponent";
import platformInfo from "lib/platformInfo";
import { isEqual } from "lodash";
import { mixpanel } from "components/Mixpanel";
import moment from "moment";
import { SimpleListItem } from "components/DropDown/ListItems";
import DownloadIcon from "assets/images/icons/JSX/Icon-Download";
import { calculateGrowth, toPrettyNumber } from "lib/metricCalculations";
import { PAID_PLATFORMS } from "lib/paidPlatforms";

const { toggleExportManager } = actions;

const cleanValue = (v) => (v && !isNaN(v) ? toPrettyNumber(v) : null);
export const budgetDataToExport = (views, accountsPerformance, month) =>
    Object.values(accountsPerformance).map((account) => {
        const { integrations = {}, goals = {} } = views[account?.id] || {};
        const budgetPlatforms = PAID_PLATFORMS.filter((platform) => account?.id === "total" || integrations[platform]);

        const start = moment(month)
            .startOf("month")
            .format("YYYY-MM-DD");
        const end = moment(month)
            .endOf("month")
            .format("YYYY-MM-DD");

        const isInBudgetGroupRange = moment().isBetween(start, end, "day", []);
        const daysInBudgetGroup = moment(end).diff(start, "days") + 1;
        const daysFromStartOfBudgetGroup =
            moment()
                .add(-1, "days")
                .diff(start, "days") + 1;
        const daysIntoBudgetGroup = isInBudgetGroupRange ? daysFromStartOfBudgetGroup : daysInBudgetGroup;
        const percentDaysIntoBudgetGroup = daysIntoBudgetGroup / daysInBudgetGroup;

        const paidPlatformData = budgetPlatforms.reduce((cache, platform) => {
            const { data: { [`${platform}-cost`]: { actual: spend = 0 } = {} } = {} } = account?.metrics || {};
            const {
                values: {
                    [moment(month)
                        .startOf("month")
                        .format("YYYY-MM-DD")]: { [`${platform}Budget`]: { value: budget = 0 } = {} } = {},
                } = {},
            } = goals || {};
            return {
                ...cache,
                [platform]: {
                    spend,
                    budget: parseFloat(budget),
                    expectedSpend: budget * percentDaysIntoBudgetGroup,
                },
            };
        }, {});
        let spend = budgetPlatforms.reduce((sum, platform) => {
            return sum + paidPlatformData[platform].spend;
        }, 0);
        let monthlyBudget = budgetPlatforms.reduce((sum, platform) => {
            return sum + paidPlatformData[platform].budget;
        }, 0);
        let expectedSpend = budgetPlatforms.reduce((sum, platform) => {
            return sum + paidPlatformData[platform].expectedSpend;
        }, 0);

        return {
            "Account Name": account?.name,
            AccountId: account?.id,
            [`${moment().format("MMMM")} Budget`]: cleanValue(monthlyBudget),
            "Spend To Date": cleanValue(spend),
            "Expected Spend": cleanValue(expectedSpend),
        };
    });

export const metricsDataToExport = (
    accountsPerformance,
    metricsToExport = ["custom-cost", "custom-clicks", "custom-conversions"],
) =>
    Object.values(accountsPerformance).map((account) => {
        return {
            Name: account?.name,
            AccountId: account?.id,
            ...Object.keys(account?.metrics?.data || {})
                .filter((key) => metricsToExport.includes(key))
                .reduce((cache, key) => {
                    const metric = account?.metrics?.data[key];
                    const [platformKey, metricKey] = key.split("-");
                    return {
                        ...cache,
                        [`${channelFriendlyName(platformKey)} - ${metricFriendlyName(
                            metricKey,
                            platformKey,
                        )} (Actual)`]: cleanValue(metric?.actual),
                        [`${channelFriendlyName(platformKey)} - ${metricFriendlyName(
                            metricKey,
                            platformKey,
                        )} (Month Before)`]: cleanValue(metric?.oneMonth),
                    };
                }, {}),
        };
    });

export const metricsGrowthDataToExport = (
    accountsPerformance,
    metricsToExport = ["custom-cost", "custom-clicks", "custom-conversions"],
) =>
    Object.values(accountsPerformance).map((account) => {
        return {
            Name: account?.name,
            AccountId: account?.id,
            ...Object.keys(account?.metrics?.data || {})
                .filter((key) => metricsToExport.includes(key))
                .reduce((cache, key) => {
                    const metric = account?.metrics?.data[key];
                    const [platformKey, metricKey] = key.split("-");
                    return {
                        ...cache,
                        [`${channelFriendlyName(platformKey)} - ${metricFriendlyName(
                            metricKey,
                            platformKey,
                        )}`]: `${cleanValue(metric?.actual)} (${cleanValue(
                            calculateGrowth(metric?.actual, metric?.oneMonth),
                        )}%)`,
                    };
                }, {}),
        };
    });

const ManageExport = React.memo(({} = {}) => {
    const dispatch = useDispatch();
    const show = useSelector((state) => selectShowExportManager(state));
    const accountMetricsData = useSelector((state) => makeSelectAccountsMetrics()(state));
    const savedMetrics = useSelector((state) => selectSavedMetrics(state));
    const customMetrics = useSelector((state) => makeSelectCustomMetrics()(state));
    const views = useSelector((state) => makeSelectAccounts()(state));

    const [activeTab, toggleTab] = useState(null);
    const [selectedMetrics, toggleSelected] = useState([]);

    const handleChange = useCallback(({ id, checked }) => {
        if (checked) {
            toggleSelected((selected) => [...selected, id]);
        } else {
            toggleSelected((selected) => selected.filter((metric) => metric !== id));
        }
    }, []);

    const handleChangeAll = useCallback((options, isAllSelected) => {
        const optionKeys = options.map(({ name }) => name);
        if (isAllSelected) {
            toggleSelected((selected) => [...selected, ...optionKeys]);
        } else {
            toggleSelected((selected) => selected.filter((metric) => !optionKeys.includes(metric)));
        }
    }, []);

    const handleExportClick = useCallback(() => {
        mixpanel.track("Overview - Export");
    }, []);

    useEffect(() => {
        const savedMetricsArray = Object.keys(savedMetrics).map((key) => savedMetrics[key]);
        toggleSelected(savedMetricsArray);
    }, [savedMetrics]);

    const combineMetrics = {
        ...defaultGoals,
        ...Object.keys(customMetrics).reduce((cache, metric) => ({ ...cache, [metric]: { active: false } }), {}),
    };

    const commonMetrics = Object.keys(combineMetrics).filter((key) => {
        // removing all metrics related to Google Analytics channels
        return !key.startsWith("analytics") || key.startsWith("analyticsV4-");
    });

    const listPlatform = Object.keys(platformInfo).filter((platform) => !platform.includes("campaignGroup"));

    const tabs = listPlatform.map((platform) => {
        return {
            id: platform,
            name: platformInfo[platform]?.icon,
            info: channelFriendlyName(platform),
        };
    });

    const tabContents = listPlatform.map((platform) => {
        const platformPrefix = platform;
        const filterByPlatform = commonMetrics.filter((id) => !platform || id.includes(platformPrefix + "-"));

        return (
            <GoalSelector
                key={platform}
                handleChange={handleChange}
                handleChangeAll={handleChangeAll}
                selectedMetrics={selectedMetrics}
                metrics={filterByPlatform}
                searchText={`Search ${platformInfo[platform]?.name} Metrics`}
            />
        );
    });

    const selectedTab =
        selectedMetrics.length > 0
            ? [
                  {
                      id: "selected",
                      name: `Selected (${selectedMetrics.length})`,
                  },
              ]
            : [];

    const selectedTabContent =
        selectedMetrics.length > 0
            ? [
                  <GoalSelector
                      key={"selectedTabContent"}
                      handleChange={handleChange}
                      handleChangeAll={handleChangeAll}
                      selectedMetrics={selectedMetrics}
                      metrics={commonMetrics.filter((id) => selectedMetrics.includes(id))}
                      searchText={`Search All Metrics`}
                  />,
              ]
            : [];

    return (
        <DialogVFour
            open={show}
            classes={{
                rootClass: style.exportSettingsRoot,
                paperClass: style.exportSettingsPaper,
            }}
        >
            <CloseDialog closeActions={() => dispatch(toggleExportManager())} />
            <div className={style.exportSettings}>
                <div className={style.heading}>
                    <h3>Export Performance Overview Data</h3>
                    <p>Select the metrics you would like to export to a CSV.</p>
                </div>
                <h4>Select Metrics To Export</h4>
                <div className={style.selectionContainer}>
                    <SmallTabs
                        initialTab={0}
                        customTabType={activeTab || 0}
                        onChange={(tabType, changeType) => toggleTab(tabType)}
                        tabs={[...selectedTab, ...tabs]}
                    >
                        {[...selectedTabContent, ...tabContents]}
                    </SmallTabs>
                </div>
                <div className={style.buttons}>
                    <CSVLink
                        data={metricsDataToExport(accountMetricsData, selectedMetrics)}
                        filename={`Performance-Overview.csv`}
                        className={style.csvLink}
                    >
                        <Button medium onClick={handleExportClick}>
                            Export
                        </Button>
                    </CSVLink>
                    <Button medium secondary onClick={() => dispatch(toggleExportManager())}>
                        Cancel
                    </Button>
                </div>
            </div>
        </DialogVFour>
    );
}, isEqual);

export const ExportBudgetData = ({ month }) => {
    const views = useSelector((state) => makeSelectAccounts()(state));
    const accountsPerformance = useSelector((state) => makeSelectAccountsPerformance()(state));

    return (
        <CSVLink
            data={budgetDataToExport(views, accountsPerformance, month)}
            filename={`Performance-Overview.csv`}
            className={style.csvLink}
        >
            <SimpleListItem
                className={style.option}
                onClick={() => null}
                label={
                    <div className={style.menuItem}>
                        <DownloadIcon />
                        Export Budget Performance
                    </div>
                }
            />
        </CSVLink>
    );
};

const GoalSelector = ({ handleChange, handleChangeAll, selectedMetrics, metrics = [], searchText = "" }) => {
    return (
        <CheckboxSearch
            className={style.checkboxSearch}
            search={{
                accessor: "searchString",
                placeholder: searchText,
            }}
            options={metrics.map((id) => {
                const [platformKey, ...metricKey] = id.split("-");

                const platformMainKey = getPlatformMainKey(platformKey);
                const channel = platformInfo[platformMainKey]?.name;
                const PlatformIcon = platformInfo[platformMainKey]?.icon;

                const formatChannel = (
                    <span className={style.formatChannel} key={id}>
                        {PlatformIcon && <PlatformIcon />}{" "}
                        <PlatformAndChannelLabel
                            channelKey={platformKey}
                            fallback={channel}
                            className={style.channelLabel}
                        />
                    </span>
                );

                return {
                    label: capitalize(metricFriendlyName(metricKey.join("-"), platformKey)),
                    labelDetails: <span>{formatChannel}</span>,
                    name: id,
                    id: id,
                    tip: `<span style="display:block;max-width:15rem;">${metricInfo(id, platformKey)?.tip}</span>`,
                    checked: selectedMetrics.includes(id),
                    platformKey: platformMainKey,
                    platformChannelName: channelFriendlyName(platformKey),
                    searchString: `${capitalize(
                        metricFriendlyName(metricKey.join("-"), platformKey),
                    )} ${channelFriendlyName(platformKey)}`,
                };
            })}
            onChange={handleChange}
            onChangeAll={handleChangeAll}
            itemType="Metrics"
            searchTermInText
            sortFn={platformGoalsSort}
            lazyLoad
        />
    );
};

export default ManageExport;
