import React, { useState, useCallback, useEffect, useMemo } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Popper, ClickAwayListener } from "@material-ui/core";
import style from "./style.module.scss";
import classNames from "classnames";

import { makeSelectUserId } from "_redux/users/selectors";
import { selectOverviewTagsAvailable, selectOverviewUi } from "../ducks/selectors";
import { operations } from "../ducks";
import { createOverviewTag, deleteOverviewTag, editOverviewTag } from "features/ControlPanel/ducks/operations";

import IconClose from "assets/images/icons/JSX/Icon-Close-Small";
import { ReactComponent as TagIcon, ReactComponent as IconTag } from "assets/images/icons/Icon-Tag.svg";
import EditIcon from "assets/images/icons/JSX/Icon-Edit";
import TrashIcon from "assets/images/Dashboard/JSX/Trash";

import { TooltipDarkPortal, TooltipDark } from "components/Tooltip";
import CheckboxSearch from "components/SearchBox/CheckboxSearchWithComponent";
import Button from "components/Button";
import { mixpanel } from "components/Mixpanel";
import RenderInput from "components/RenderInput";
import { hexToRGB, getNextTagColor, generateTagId } from "lib/accountTags";
import isEqual from "lodash/isEqual";
import get from "lodash/get";
import { channelFriendlyName } from "lib/utility";
import PlatformIcon from "components/Icon/PlatformIcon";
import Tag from "components/Tag";
import AddButton from "features/ControlPanel/AddButton";
import { getPlatformId } from "features/Dashboard/GoalSummary/GoalCard/Header/helpers";
import classnames from "classnames";
import { Permissions } from "features/Permissions";

const { updateAccountTags } = operations;

export const AccountTag = ({
    className,
    tag,
    id,
    tip,
    tipId,
    color = "rgba(160, 181, 196, 0.12)",
    hexColor = "",
    onRemove,
    active = false,
    isLarge = false,
    tooltipPosition,
}) => (
    <div
        className={classNames(style.tag, { [className]: className, [style.active]: active, [style.large]: isLarge })}
        style={{ backgroundColor: color }}
        data-tip={tip}
        data-for={tipId}
        data-html
    >
        <span>{tag}</span>
        {onRemove && (
            <div className={style.remove} onClick={() => onRemove(id)}>
                <IconClose />
            </div>
        )}
        {tip && <TooltipDark id={tipId} proximity={2} position={tooltipPosition} />}
        {!onRemove && hexColor && <div className={style.tagBorder} style={{ borderColor: hexColor }}></div>}
    </div>
);

const AccountTags = React.memo(
    ({
        className,
        tags: unfilteredTags = [],
        accountId,
        tooltipPosition,
        integrations = {},
        onAddIntegration,
        selectView,
    }) => {
        const availableTags = useSelector((state) => selectOverviewTagsAvailable(state));
        const { tags: unfilteredFilterTAags = [] } = useSelector((state) => selectOverviewUi(state));

        const tags = unfilteredTags.filter((tag) => availableTags.find((t) => tag === t.id));
        const filterTags = unfilteredFilterTAags.filter((tag) => availableTags.find((t) => tag === t.id));

        const classes = classNames(style.accountWrapper, {
            [className]: className,
        });

        const size = tags.length;
        const tagsTooltip = tags.map(
            (tag) => `<span style="display:block;">${availableTags.find((t) => tag === t.id)?.name}</span>`,
        );
        const tagsActive = tags.some((tag) => filterTags.includes(tag));
        const [firstTag] = tags;
        const firstTagInfo = availableTags.find((t) => firstTag === t.id) || {};
        const filteredIntegrations = Object.keys(integrations).filter(
            (platform) => platform !== "undefined" && platform !== "analytics",
        );

        return (
            <div className={classes}>
                <div className={style.accountTags}>
                    {filteredIntegrations.map((platform) => {
                        return (
                            <div
                                key={platform}
                                onClick={() =>
                                    getPlatformId(platform) &&
                                    selectView({
                                        accountId,
                                        navigateTo: `/dashboard/${accountId}/kpi/${getPlatformId(platform)}`,
                                    })
                                }
                            >
                                <Tag
                                    className={classnames(style.platformTag, {
                                        [style.hasReport]: getPlatformId(platform),
                                    })}
                                    icon={<PlatformIcon platform={platform} className={style.platformTagIcon} />}
                                    tip={channelFriendlyName(platform)}
                                    tipId="suggestion-tag"
                                ></Tag>
                            </div>
                        );
                    })}

                    {filteredIntegrations.length > 0 && (
                        <Permissions actions={"ACTION_ACCOUNT_SETTINGS_INTEGRATIONS"}>
                            <AccountTag
                                className={style.addButton}
                                tag={<AddButton justIcon onClick={onAddIntegration} />}
                                tip="Add Integration"
                                tipId={`addAccount-${accountId}`}
                            />
                        </Permissions>
                    )}
                </div>
                <div className={style.accountTags}>
                    {size === 1 && (
                        <AccountTag
                            key={`tag-${accountId}`}
                            color={firstTagInfo?.color}
                            tag={firstTagInfo?.name}
                            tip={`<span style="display:block;min-width:120px">${tagsTooltip.join("")}</span>`}
                            tipId={`tagsTooltip-${accountId}`}
                            hexColor={filterTags.includes(firstTagInfo) ? firstTagInfo?.hexColor : null}
                            active={filterTags.includes(firstTagInfo)}
                            tooltipPosition={tooltipPosition}
                        />
                    )}
                    {size > 1 && (
                        <AccountTag
                            tag={
                                <div className={style.tagName}>
                                    <IconTag /> {tagsTooltip.length} Tags
                                </div>
                            }
                            tip={`<span style="display:block;min-width:120px">${tagsTooltip.join("")}</span>`}
                            tipId={`tagsTooltip-${accountId}`}
                            active={tagsActive}
                            hexColor="#A0B5C4"
                            tooltipPosition={tooltipPosition}
                        />
                    )}
                </div>
            </div>
        );
    },
    isEqual,
);

export const ManageTags = React.memo(({ tags: unfilteredTags, accountId, iconClass }) => {
    const dispatch = useDispatch();
    const userIdSelector = useMemo(makeSelectUserId);
    const userId = useSelector((state) => userIdSelector(state));
    const availableTags = useSelector((state) => selectOverviewTagsAvailable(state));

    const tags = unfilteredTags.filter((tag) => availableTags.find((t) => tag === t.id));

    const [referenceElement, setReferenceElement] = useState(null);
    const [view, toggleView] = useState("select");
    const [editTag, toggleEditTag] = useState("");
    const [selectedTags, updateTags] = useState(tags);
    const [showManage, toggleManage] = useState(false);

    const createTag = useCallback(
        (newTag) => {
            const newTagId = generateTagId();
            dispatch(createOverviewTag({ tagName: newTag, tagId: `${newTagId}-${userId}` }));
            updateTags((selected) => [...selected, `${newTagId}-${userId}`]);
            mixpanel.track("Overview - Add Tag", {
                Tag: newTag,
            });
            toggleView("select");
        },
        [updateTags, toggleView, dispatch, userId],
    );

    const handleTagSave = useCallback(
        ({ id, newName }) => {
            dispatch(editOverviewTag({ tagId: id, tagName: newName }));
            mixpanel.track("Overview - Edit Tag", {
                Tag: newName,
            });
            toggleView("select");
        },
        [dispatch],
    );

    const handleTagDelete = useCallback(
        (tag) => {
            dispatch(deleteOverviewTag({ tagId: tag }));
            mixpanel.track("Overview - Delete Tag", {
                Tag: tag,
            });
            toggleView("select");
        },
        [dispatch],
    );

    const handleManageTags = () => {
        toggleManage(!showManage);
        // Closing
        if (showManage && availableTags.length > 0) {
            toggleView("select");
        }
    };

    useEffect(() => {
        if (availableTags.length === 0) {
            toggleView("add");
        } else {
            toggleView("select");
        }
    }, [availableTags.length]);

    useEffect(() => {
        const tags = unfilteredTags.filter((tag) => availableTags.find((t) => tag === t.id));
        updateTags((selected) => [...new Set([...selected, ...tags])]);
    }, [unfilteredTags, availableTags]);

    return (
        <ClickAwayListener onClickAway={() => toggleManage(false)}>
            <div className={style.manageTagsWrapper}>
                <div
                    ref={setReferenceElement}
                    className={classNames(style.manageTagsIcon, {
                        [style.isOpen]: showManage,
                        [iconClass]: iconClass,
                    })}
                    data-for={`${accountId}-manage-tags`}
                    data-tip="Manage Tags"
                    onClick={handleManageTags}
                >
                    <TagIcon />
                </div>
                <Popper
                    open={showManage}
                    anchorEl={referenceElement}
                    transition
                    placement="bottom-start"
                    style={{ zIndex: 10 }}
                    modifiers={{
                        flip: {
                            enabled: false,
                        },
                    }}
                >
                    <div className={classNames(style.manageTags, { [style.isOpen]: showManage })}>
                        <div className={style.switchContainer}>
                            {view === "select" && (
                                <TagSelect
                                    availableTags={availableTags}
                                    tags={tags}
                                    accountId={accountId}
                                    toggleManage={toggleManage}
                                    toggleView={toggleView}
                                    selectedTags={selectedTags}
                                    updateTags={updateTags}
                                    userId={userId}
                                    toggleEditTag={toggleEditTag}
                                    isOpen={showManage}
                                />
                            )}
                            {view === "add" && (
                                <TagAdd
                                    availableTags={availableTags}
                                    toggleView={toggleView}
                                    toggleManage={toggleManage}
                                    createTag={createTag}
                                />
                            )}
                            {view === "edit" && (
                                <TagEdit
                                    availableTags={availableTags}
                                    toggleView={toggleView}
                                    toggleManage={toggleManage}
                                    tag={availableTags.find(({ id }) => id === editTag)?.name}
                                    tagId={editTag}
                                    saveTag={(tag) => handleTagSave({ id: editTag, newName: tag })}
                                    deleteTag={() => handleTagDelete(editTag)}
                                />
                            )}
                        </div>
                    </div>
                </Popper>
                <TooltipDarkPortal id={`${accountId}-manage-tags`} proximity={2} delay />
            </div>
        </ClickAwayListener>
    );
}, isEqual);

const TagSelect = ({
    availableTags,
    accountId,
    toggleManage,
    toggleView,
    selectedTags,
    updateTags,
    toggleEditTag,
    isOpen,
}) => {
    const dispatch = useDispatch();

    const toggleTags = useCallback(
        ({ id, checked }) => {
            const tagId = availableTags.find((t) => t.name === id)?.id;
            if (checked) {
                updateTags((selected) => [...selected, tagId]);
            } else {
                updateTags((selected) => selected.filter((tag) => tag !== tagId));
            }
        },
        [availableTags, updateTags],
    );

    const applyTags = useCallback(() => {
        dispatch(updateAccountTags({ accountId, tags: selectedTags }));
        toggleManage(false);
        mixpanel.track("Overview - Apply Tags", {
            Tags: selectedTags.map((tagId) => availableTags.find((t) => t.id === tagId)?.name).join(", "),
        });
    }, [accountId, dispatch, selectedTags, toggleManage, availableTags]);

    return (
        <CheckboxSearch
            className={style.tagSearch}
            toggleFocus={isOpen}
            search={{
                placeholder: `Search Tags`,
                accessor: "id",
                large: true,
                zeroState: () =>
                    availableTags.length > 0 ? (
                        <div className={style.emptySearch}>There are no tags that match your search.</div>
                    ) : (
                        <div className={style.emptySearch}>Create a tag to add to your account.</div>
                    ),
            }}
            options={availableTags.map(({ name = "", id, color, hexColor }, index) => ({
                label: (
                    <>
                        <AccountTag color={color} hexColor={hexColor} tag={name} />
                        <div
                            className={style.editTag}
                            onClick={(e) => {
                                e.stopPropagation();
                                e.preventDefault();
                                toggleEditTag(id);
                                toggleView("edit");
                            }}
                            data-for={`edit-${index}`}
                            data-tip="Edit Tag"
                        >
                            <EditIcon />
                        </div>
                        <TooltipDark id={`edit-${index}`} proximity={2} delay />
                    </>
                ),
                id: name,
                checked: selectedTags.includes(id),
                className: style.tagCheckbox,
            }))}
            itemType="Tags"
            onChange={toggleTags}
            bottomBar={() => (
                <div className={style.searchFooter}>
                    <span className={style.addNew} onClick={() => toggleView("add")}>
                        + Add New Tag
                    </span>
                    <Button small onClick={applyTags}>
                        Apply
                    </Button>
                </div>
            )}
            hideSelectAll
            extraContent={<span className={style.tagsTitle}>Tags</span>}
            sortFn={(a, b, path) => {
                const tagA = get(a, path);
                const tagB = get(b, path);

                const aVal = availableTags.find((t) => t.name === tagA)?.index;
                const bVal = availableTags.find((t) => t.name === tagB)?.index;
                return aVal > bVal ? 1 : aVal < bVal ? -1 : 0;
            }}
        />
    );
};

export const TagAdd = ({ availableTags, toggleView, toggleManage, createTag }) => {
    const [newTag, updateNewTag] = useState("");

    const newTagColor = getNextTagColor(availableTags);
    const tagAlreadyExists = availableTags.find((t) => newTag === t?.name);

    const cancelAdd = useCallback(() => {
        if (availableTags.length > 0) {
            toggleView("select");
        } else {
            toggleManage(false);
        }
        updateNewTag("");
    }, [toggleView, availableTags, toggleManage]);

    return (
        <div className={style.addTagContainer}>
            <div className={style.heading}>
                <span className={style.title}>Add New Tag</span>
                <span className={style.subTitle}>Set the name of your tag</span>
            </div>
            <div className={style.inputWrapper}>
                <RenderInput
                    className={style.tagInput}
                    input={{
                        value: newTag,
                        onChange: (event) => {
                            updateNewTag(event.target.value);
                        },
                    }}
                    name="tag"
                    label="Tag Name"
                    meta={{
                        error: tagAlreadyExists
                            ? "This tag already exists"
                            : newTag.length > 24
                            ? "Tag Name cannot be longer than 24 characters"
                            : "",
                        touched: tagAlreadyExists || newTag.length > 24,
                    }}
                />
                <div
                    className={style.tagColor}
                    style={{ backgroundColor: hexToRGB({ hex: newTagColor, opacity: 0.12 }), borderColor: newTagColor }}
                ></div>
            </div>
            <div className={style.addTagFooter}>
                <Button hollow small onClick={cancelAdd}>
                    Cancel
                </Button>
                <Button
                    small
                    onClick={() => createTag(newTag)}
                    disabled={!newTag || tagAlreadyExists || newTag.length > 24}
                >
                    Create
                </Button>
            </div>
        </div>
    );
};

export const TagEdit = ({ availableTags, toggleView, toggleManage, tag, saveTag, deleteTag }) => {
    const [newTag, updateNewTag] = useState("");

    const color = availableTags.find((t) => tag === t.name)?.hexColor;
    const index = availableTags.find((t) => tag === t.name)?.index;
    const tagAlreadyExists = availableTags.filter((t) => t.name !== tag).find((t) => newTag === t?.name);

    const cancelEdit = useCallback(() => {
        if (availableTags.length > 0) {
            toggleView("select");
        } else {
            toggleManage(false);
        }
        updateNewTag(tag);
    }, [toggleView, availableTags, toggleManage, tag]);

    useEffect(() => {
        updateNewTag(tag);
    }, [tag]);

    return (
        <div className={style.addTagContainer}>
            <div className={style.heading}>
                <span className={style.title}>Edit Tag</span>
                <span className={style.subTitle}>Edit the name of your tag</span>
            </div>
            <div className={style.inputWrapper}>
                <RenderInput
                    className={style.tagInput}
                    input={{
                        value: newTag,
                        onChange: (event) => {
                            updateNewTag(event.target.value);
                        },
                    }}
                    name="tag"
                    label="Tag Name"
                    meta={{
                        error: tagAlreadyExists
                            ? "This tag already exists"
                            : newTag.length > 24
                            ? "Tag Name cannot be longer than 24 characters"
                            : "",
                        touched: tagAlreadyExists || newTag.length > 24,
                    }}
                />
                <div
                    className={style.tagColor}
                    style={{ backgroundColor: hexToRGB({ hex: color, opacity: 0.12 }), borderColor: color }}
                ></div>
            </div>
            <div className={style.addTagFooter}>
                <div className={style.deleteTag} onClick={deleteTag} data-for={`delete-${index}`} data-tip="Delete Tag">
                    <TrashIcon />
                </div>
                <TooltipDark id={`delete-${index}`} proximity={2} delay />
                <Button hollow small onClick={cancelEdit}>
                    Cancel
                </Button>
                <Button
                    small
                    onClick={() => saveTag(newTag)}
                    disabled={!newTag || tagAlreadyExists || newTag.length > 24}
                >
                    Save
                </Button>
            </div>
        </div>
    );
};

export default AccountTags;
