import clearReducer from "lib/clearReducer";
import createReducer from "lib/createReducer";
import { get } from "lodash";
import { handle } from "redux-pack";
import types from "./types";

const defaultState = {
    report: [],
    reportLoading: undefined,
    reportError: undefined,
    orders: {},
    ordersLoading: undefined,
    ordersError: undefined,
};

export default clearReducer(defaultState)(
    createReducer(defaultState, {
        [types.SHOPIFY_REPORT]: (state, action) =>
            handle(state, action, {
                start: (prevState) => ({
                    ...prevState,
                    reportLoading: true,
                    reportError: undefined,
                    report: [],
                }),
                finish: (prevState) => ({
                    ...prevState,
                    reportLoading: false,
                }),
                success: (prevState) => {
                    const isCancelledReq = get(action, ["payload", "isCancelledReq"]);
                    const { payload = [] } = action;
                    const report = isCancelledReq ? [] : payload;

                    return {
                        ...prevState,
                        report,
                        reportLoading: false,
                        reportError: undefined,
                    };
                },
                failure: (prevState) => {
                    return {
                        ...prevState,
                        report: [],
                        reportLoading: false,
                        reportError: get(action, ["payload", "response", "data", "error"]) || "Something went wrong",
                    };
                },
            }),
        [types.SHOPIFY_ORDERS]: (state, action) =>
            handle(state, action, {
                start: (prevState) => ({
                    ...prevState,
                    ordersLoading: true,
                    ordersError: undefined,
                }),
                finish: (prevState) => ({
                    ...prevState,
                    ordersLoading: false,
                }),
                success: (prevState) => {
                    const isCancelledReq = get(action, ["payload", "isCancelledReq"]);
                    const { payload = {} } = action;
                    const orders = isCancelledReq ? {} : { ...payload };

                    return {
                        ...prevState,
                        orders,
                        ordersLoading: false,
                        ordersError: undefined,
                    };
                },
                failure: (prevState) => {
                    return {
                        ...prevState,
                        orders: {},
                        ordersLoading: false,
                        ordersError: get(action, ["payload", "response", "data", "error"]) || "Something went wrong",
                    };
                },
            }),
    }),
);
