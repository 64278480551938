import createReducer from "../../lib/createReducer";
import { operations } from "../../features/ControlPanel/ducks";
import { getUserSubscription, getBillingDetails } from "../users";
import { getUserPermissions } from "features/Permissions/operations";

import { logout, identifyUser } from "../../lib/auth";
import { changeAccount } from "_redux/sites";
import { isOnDefaultDomain } from "components/Branding";
import { getSelectedWorkspaceId } from "lib/workspaces";

const { getSites } = operations;

/**
 *  ACTION TYPES
 */

const PRELOAD_DATA_REJECTED = "PRELOAD_DATA_REJECTED";
const PRELOAD_DATA_FUFILLED = "PRELOAD_DATA_FUFILLED";
const PRELOAD_DATA_PENDING = "PRELOAD_DATA_PENDING";

/**
 *  ACTIONS
 */

export const preloadDataPending = ({ pending } = {}) => ({
    type: PRELOAD_DATA_PENDING,
    pending,
});

export const preloadDataFufilled = ({ payload } = {}) => ({
    type: PRELOAD_DATA_FUFILLED,
    payload,
});

export const preloadDataRejected = ({ error, payload } = {}) => ({
    type: PRELOAD_DATA_REJECTED,
    error,
    payload,
});

export const preloadData = (accountId) => async (dispatch) => {
    dispatch(
        preloadDataPending({
            pending: true,
        }),
    );

    const { error, payload } = await dispatch(getUserSubscription());

    const {
        email = "",
        name = "",
        sub,
        subscription: { id: planId, isTrial, exists: planExists } = {},
        user_metadata: { company = "", nickname } = {},
        app_metadata: { role = "" } = {},
        type,
        folders,
    } = payload;

    await dispatch(getUserPermissions(sub, planId));

    const { error: billingError, payload: { data: { data: paymentSource = null } = {} } = {} } = await dispatch(
        getBillingDetails(),
    );

    // Get permissions

    if (error || billingError || !sub) {
        dispatch(
            preloadDataRejected({
                error: true,
                payload: error || billingError,
            }),
        );
        dispatch(
            preloadDataPending({
                pending: false,
            }),
        );

        // PATCH
        // WEIRD LOGOUT ISSUE ON OPEN WINDOW INTEGRETRATIONS
        if (
            window.search &&
            window.search.includes("code") &&
            window.pathname &&
            window.pathname.includes("/dashboard/account/connect")
        ) {
            return;
        }

        return logout();
    }

    const getUserSubscriptionType = () => {
        if (isTrial) {
            return "trial";
        }
        if (type === "appSumoUser") {
            return "appsumo";
        }
        if (type === "appSumoUserAnnual") {
            return "appsumo-annual";
        }
        if (planId === "freemium") {
            return "freemium";
        }
        if (planExists && paymentSource) {
            return "paid";
        }
        return "none";
    };

    if (isOnDefaultDomain()) {
        window.Intercom("boot", {
            app_id: "kf9lvt1o",
            email: email,
            name: nickname ? nickname.split(" ")[0] : "",
            user_id: sub,
            created_at: Date.now(),
            plan_type: getUserSubscriptionType(),
            userRole: role,
        });
    }

    await identifyUser({
        data: payload,
    });

    const workspaceId = getSelectedWorkspaceId();
    await dispatch(getSites(payload.sub, workspaceId));

    // this need to happen before the loading screen finishes or won't register the correct permissions
    await dispatch(changeAccount(accountId));

    dispatch(preloadDataFufilled());
    try {
        dispatch(
            preloadDataPending({
                pending: false,
            }),
        );
    } catch (e) {
        console.log(e);
    }

    return true;
};

/**
 * REDUCERS
 */

const defaultState = {
    pending: true,
};

export const preload = createReducer(defaultState, {
    [PRELOAD_DATA_FUFILLED](state, action) {
        return {
            ...state,
            fufilled: true,
        };
    },
    [PRELOAD_DATA_REJECTED](state, action) {
        return {
            ...state,
            error: action.error,
        };
    },
    [PRELOAD_DATA_PENDING](state, action) {
        return {
            ...state,
            pending: action.pending,
        };
    },
});
