import { KpiAPI } from "../apiKpi";
import { getSelectedWorkspaceId } from "lib/workspaces";

function getAccounts({ userId, connectionId }) {
    const workspaceId = getSelectedWorkspaceId();

    return KpiAPI({
        method: "GET",
        url: `/analyticsV4/accounts`,
        query: {
            userId: workspaceId || userId,
            connectionId,
        },
    })
        .then(({ data }) => {
            return {
                connectionId,
                platform: "analyticsV4",
                integrations: {
                    analyticsV4Accounts: data.accounts.reduce((cache, item) => ({ ...cache, [item.id]: item }), {}),
                    analyticsV4Properties: data.properties.reduce((cache, item) => ({ ...cache, [item.id]: item }), {}),
                },
            };
        })
        .catch((error) => ({ data: { error: true } }));
}

export default {
    getAccounts,
};
