import { isEmpty } from "lodash";
import reduce from "lodash/fp/reduce";
import { compose } from "recompose";

const formatProducts = (products = []) => {
    const productsLineItems =
        (Array.isArray(products) && products?.flatMap((product) => product?.line_items || [])) || [];

    return productsLineItems.reduce((cache, item) => {
        const { quantity: prevQuantity = 0, sales: prevPrice = 0, discount: prevDiscount = 0 } =
            cache[item.product_id] || {};

        return {
            ...cache,
            [item.product_id]: {
                ...item,
                quantity: parseFloat(item.quantity) + prevQuantity,
                sales: parseFloat(item.price) * parseFloat(item.quantity) + prevPrice,
                discount: parseFloat(item.total_discount) + prevDiscount,
            },
        };
    }, {});
};

export const formatShopifyProductsData = (orders) => {
    if (isEmpty(orders)) {
        return {};
    }

    const products = formatProducts(orders.current);
    const productsPrevious = formatProducts(orders.previous);

    if (isEmpty(products)) {
        return {};
    }

    return {
        products: compose(
            reduce((cache, productId) => {
                return [...cache, products[productId]];
            }, []),
        )(Object.keys(products || {})),
        productsPrevious: compose(
            reduce((cache, productId) => {
                return [...cache, productsPrevious[productId]];
            }, []),
        )(Object.keys(productsPrevious || {})),
    };
};
