import moment from "moment";
import { KpiAPI } from "../apiKpi";
import { getSelectedWorkspaceId } from "lib/workspaces";

function formatDateRange(dateRange = {}) {
    if (!dateRange) {
        return "";
    }

    try {
        const validatedDateRange = dateRange
            .filter((obj) => {
                return obj.hasOwnProperty("start") && obj.hasOwnProperty("end");
            })
            .map(({ start, end }) => {
                return {
                    start: moment(start).format("YYYY-MM-DD"),
                    end: moment(end).format("YYYY-MM-DD"),
                };
            });

        return JSON.stringify(validatedDateRange);
    } catch (error) {
        return "";
    }
}

function getPages({ userId, connectionId }) {
    const workspaceId = getSelectedWorkspaceId();

    return KpiAPI({
        method: "GET",
        url: `/organicInstagram/pages`,
        query: {
            userId: workspaceId || userId,
            connectionId,
        },
    })
        .then(({ data }) => {
            return {
                connectionId,
                platform: "organicInstagram",
                integrations: {
                    organicInstagram: data.reduce(
                        (obj, next) => ({
                            ...obj,
                            [next.id]: next,
                        }),
                        {},
                    ),
                },
            };
        })
        .catch((error) => ({ data: { error: true } }));
}

export function getReport({ dateRange, accountId, integrationId, abortController }) {
    const validDateRange = formatDateRange(dateRange);

    return KpiAPI({
        method: "GET",
        url: "/organicInstagram/reports",
        query: {
            accountId,
            integrationId,
            dateRange: validDateRange,
            startDate: moment(dateRange.start).format("YYYY-MM-DD"),
            endDate: moment(dateRange.end).format("YYYY-MM-DD"),
        },
        abortController,
    }).then(({ data } = {}) => {
        return data;
    });
}

export function getDemographic({ accountId, integrationId, types, abortController }) {
    return KpiAPI({
        method: "GET",
        url: "/organicInstagram/demographics",
        query: {
            accountId,
            integrationId,
            types: JSON.stringify(types),
        },
        abortController,
    }).then(({ data } = {}) => {
        return data;
    });
}

export function getTopPosts({ startDate, endDate, accountId, integrationId, abortController }) {
    return KpiAPI({
        method: "GET",
        url: "/organicInstagram/topPosts",
        query: {
            accountId,
            integrationId,
            startDate: moment(startDate).format("YYYY-MM-DD"),
            endDate: moment(endDate).format("YYYY-MM-DD"),
        },
        abortController,
    }).then(({ data } = {}) => {
        return data;
    });
}

export default {
    getPages,
    getReport,
    getDemographic,
    getTopPosts,
};
