import { KpiAPI } from "../apiKpi";
import moment from "moment";
import { getSelectedWorkspaceId } from "lib/workspaces";

function getAccounts({ userId, connectionId }) {
    const workspaceId = getSelectedWorkspaceId();

    return KpiAPI({
        method: "GET",
        url: `/bing/accounts`,
        query: {
            userId: workspaceId || userId,
            connectionId,
        },
    })
        .then(({ data }) => {
            return {
                connectionId,
                platform: "bing",
                integrations: {
                    bing: data.reduce(
                        (obj, next) => ({
                            ...obj,
                            [next.id]: next,
                        }),
                        {},
                    ),
                },
            };
        })
        .catch((error) => ({ data: { error: true } }));
}

function getCampaigns({ userId, connectionId, bingId }) {
    const workspaceId = getSelectedWorkspaceId();

    return KpiAPI({
        method: "GET",
        url: `/bing/campaigns`,
        query: {
            userId: workspaceId || userId,
            connectionId,
            bingId,
        },
    })
        .then(({ data }) => {
            return {
                connectionId,
                bingId,
                campaigns: {
                    bing: data,
                },
            };
        })
        .catch((error) => ({ data: { error: true } }));
}

export function getSearchTermsReport({ dateRange, accountId }) {
    if (dateRange) {
        const validatedDateRange = dateRange
            .filter((obj) => {
                return obj.hasOwnProperty("start") && obj.hasOwnProperty("end");
            })
            .map(({ start, end }) => {
                return {
                    start: moment(start).format("YYYY-MM-DD"),
                    end: moment(end).format("YYYY-MM-DD"),
                };
            });

        dateRange = JSON.stringify(validatedDateRange);
    }

    return KpiAPI({
        method: "GET",
        url: "/bing/searchTerms",
        query: {
            accountId,
            dateRange,
            startDate: moment(dateRange.start).format("YYYY-MM-DD"),
            endDate: moment(dateRange.end).format("YYYY-MM-DD"),
        },
    }).then(({ data } = {}) => {
        return data;
    });
}

export default {
    getAccounts,
    getCampaigns,
    getSearchTermsReport,
};
