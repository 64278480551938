import { KpiAPI } from "lib/apiKpi";

export function getReport({ dateRange = [], accountId, abortController }) {
    return KpiAPI({
        method: "GET",
        url: `/shopify/reports`,
        query: {
            accountId,
            dateRange: JSON.stringify(dateRange),
        },
        abortController,
    })
        .then(({ data }) => data)
        .catch((error) => {
            throw error;
        });
}

export function getOrders({ dateRange = [], accountId, abortController }) {
    return Promise.all(
        dateRange.map(({ start, end }) =>
            KpiAPI({
                method: "GET",
                url: `/shopify/orders`,
                query: {
                    accountId,
                    startDate: start,
                    endDate: end,
                },
                abortController,
            }),
        ),
    )
        .then(([result = {}, prevResult = {}]) => {
            return {
                current: result.data || [],
                previous: prevResult.data || [],
            };
        })
        .catch((error) => {
            throw error;
        });
}

export default {
    getReport,
    getOrders,
};
